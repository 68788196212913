<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'name'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields="['id', 'name', 'iso', 'license_plate_required_export', 'license_plate_required_import']"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')"></Column>
            <Column field="iso" header="ISO"></Column>
            <Column :header="$t('overview.license_plate_required')">
              <template #body="slotProps">
                    {{checkLicencePlateRequired(slotProps.data)}}
              </template>
            </Column>
        </template>

        <template #form>
            <CountryForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import CountryForm from '@/components/country/CountryForm.vue';
import countryService from '@/services/CountryService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        CountryForm
    },
    data() {
        return {
            item: {},
            itemService: countryService,
            itemFormRef: null
        }
    },
    methods: {
        checkLicencePlateRequired(item){
            if(item.license_plate_required_export && item.license_plate_required_import){
                return this.$t('masterdata.export') + ' & ' + this.$t('masterdata.import');
            }
            if(item.license_plate_required_export){
                return this.$t('masterdata.export');
            }
            if(item.license_plate_required_import){
                return this.$t('masterdata.import');
            }
            return this.$t('form.no');
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>