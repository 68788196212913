<template v-if="formActive">
    <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">{{'1 - ' + $t('navigation.general_details')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="billoflading-tab" data-bs-toggle="tab" data-bs-target="#billoflading" type="button" role="tab" aria-controls="billoflading">{{'2 - ' + $t('navigation.bills_of_lading')}}</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="finance-tab" data-bs-toggle="tab" data-bs-target="#finance" type="button" role="tab" aria-controls="finance">{{'3 - ' + $t('masterdata.finance')}}</button>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <FormItem id="name" :label="$t('overview.name')" v-model="country.name" :required="true" />
        <FormItem id="iso" label="ISO Code (2 characters)" v-model="country.iso" :required="true" />
        <FormItem id="continent_code" label="Continent code (2 characters)" v-model="country.continent_code" />
        <FormItem id="is_eu" :label="$t('masterdata.is_eu')" v-model="country.is_eu" type="checkbox" />
        <FormItem id="registration_same_as_vat" :label="$t('crm.registration_same_as_vat')" v-model="country.registration_id_same_as_vat_id" type="checkbox" />
    </div>
    <div class="tab-pane fade" id="billoflading" role="tabpanel" aria-labelledby="billoflading-tab">
        <FormItem id="bl_collect_currency" :label="$t('masterdata.bl_collect_currency')" v-model="country.bl_collect_currency_id" type="select" :options="currencies" nameField="code" />
        <div class="row mt-1">
            <div style="width: 14.75rem"></div>
            <div class="col-2 col-form-label-sm text-center">{{$t('masterdata.export')}}</div>
            <div class="col-2 col-form-label-sm text-center">{{$t('masterdata.import')}}</div>
        </div>
        <div v-for="option, index in requiredOptions" :key="index" class="row mb-2">
            <div class="col-4 col-form-label-sm">{{ option.label }}</div>
            <div class="col-2">
                <FormItem :id="option.model + '_export'" v-model="country[option.model + '_export']" type="checkbox" />
            </div>
            <div class="col-2">
                <FormItem :id="option.model + '_import'" v-model="country[option.model + '_import']" type="checkbox" />
            </div>
        </div>
        
    </div>
    <div class="tab-pane fade" id="finance" role="tabpanel" aria-labelledby="finance-tab">
        <h5>{{$t('form.default', [$t('navigation.currencies')])}}</h5>
        <p class="col-form-label-sm">{{$t('masterdata.currency_notice')}}</p>
        <FormItem id="def_currency" :label="$t('navigation.currencies')" v-model="country.default_currency_id" type="select" :options="currencies" nameField="code" sizeItem="3" sizeLabel="3" :required="true" />
        <h5 class="mt-2">{{$tc('accounting.vat_percentages', 2)}}</h5>
        <p class="col-form-label-sm">{{$t('accounting.zero_vat_notice')}}</p>
        <div v-show="country.vat_percentages.length > 0" class="row text-center">
            <div class="col-5"></div>
            <div class="col-3 col-form-label-sm">{{$t('masterdata.is_default_sea_freight')}}</div>
        </div>
        <div class="row" v-for="vat, index in country.vat_percentages" :key="index">
            <div class="col-5">
                <FormItem type="number-with-tags" :label="$t('accounting.vat_percentages') + ' ' + (index + 1)" v-model="vat.percentage" :required="true" endTag="%" sizeItem="4" sizeLabel="8" />
            </div>
            <div class="col-3 text-center">
                <FormItem type="radio" v-model="vat.is_default_sea_freight" @onclick="setDefaultVAT(index)" :idField="1" />
            </div>
            <button class="btn col-1 pt-0" type="button" @click="removeVAT(index)">
                <i class="fa fa-trash-alt text-danger"></i>
            </button>
        </div>
        <button type="button" class="btn btn-primary" @click="addVAT">
            <i class="fa fa-plus"></i>&ensp;{{ $t('form.add', [$t('accounting.vat_percentages')]) }}
        </button>
        <h5 class="mt-4">{{$tc('masterdata.article_info', 2)}}</h5>
        <FormItem id="article_information" v-model="country.article_information" type="textarea" />
        <h5 class="mt-4">{{$tc('masterdata.vat_settings', 2)}}</h5>
        <FormItem id="charge_vat_pre_transport" :label="$t('masterdata.charge_vat_pre_transport')" v-model="country.charge_vat_pre_transport" type="checkbox" />
        <FormItem id="charge_vat_sea_freight" :label="$t('masterdata.charge_vat_sea_freight')" v-model="country.charge_vat_sea_freight" type="checkbox" />
    </div>
    </div>
</template>

<script>
    import currencyService from "@/services/CurrencyService";
    export default {
        name: 'CountryForm',
        props: ['itemData'],
        data() {
            return {
                country: {'vat_percentages': []},
                currencies: [],
                requiredOptions: [],
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.country = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let returnToTabOne = false;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                        if(field.id == 'def_currency'){
                            returnToTabOne = false;
                        }
                        else if(field.type != 'number'){
                            returnToTabOne = true;
                        }
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = (this.country.name && this.country.iso);
                if(!valid) {
                    alert(this.$t('errors.empty_fields'));
                    if(returnToTabOne){
                        document.getElementById('general-tab').click();
                    }
                    else{
                        document.getElementById('finance-tab').click();
                    }
                }
                this.country.set_vat_percentage = false;
                return valid;
            },
            getData() {
                return this.country;
            },
            setData(data) {
                this.country = data
                console.log(data);
                document.getElementById("general-tab").click();
            },
            reset() {
                this.isVATPercentages = false
                document.getElementById("general-tab").click();
                this.country = {
                    'name': '',
                    'iso': '',
                    'continent_code': null,
                    'bl_collect_currency_id': null,
                    'default_currency_id': null,
                    'construction_year_required_export': 0,
                    'colour_required_export': 0,
                    'hs_code_required_export': 0,
                    'license_plate_required_export': 0,
                    'construction_year_required_import': 0,
                    'colour_required_import': 0,
                    'hs_code_required_import': 0,
                    'license_plate_required_import': 0,
                    "is_eu": 0,
                    'vat_percentages': [{'percentage': '', 'is_default_sea_freight': 1}, {'percentage': '', 'is_default_sea_freight': 0}],
                    "article_information": null,
                    "charge_vat_pre_transport": 0,
                    "charge_vat_sea_freight": 0,
                    "registration_id_same_as_vat_id": 0
                }
                this.loading = false
                this.invalid = {}

                if(this.currencies.length == 0){
                    currencyService.index().then(response => {
                        this.currencies = response.data
                    }).catch(error => {
                        console.log('error', error)
                    })
                }
            },

            addVAT(){
                this.country.vat_percentages.push({'percentage': '', 'is_default_sea_freight': 0});
            },
            removeVAT(index){
                this.country.vat_percentages.splice(index, 1);
            },
            setDefaultVAT(defaultIndex){
                this.country.vat_percentages.forEach((vp, index) => {
                    if(index != defaultIndex) vp.is_default_sea_freight = 0;
                });
                this.country.vat_percentages[defaultIndex].is_default_sea_freight = 1;
            }
            
        },
        mounted(){
            this.requiredOptions = [
                {label: this.$t('sales.build_year') + ' ' + this.$t('form.required'), model: 'construction_year_required'},
                {label: this.$t('navigation.colours') + ' ' + this.$t('form.required'), model: 'colour_required'},
                {label: this.$t('navigation.hs_codes') + ' ' + this.$t('form.required'), model: 'hs_code_required'},
                {label: this.$t('overview.license_plate_required'), model: 'license_plate_required'}
            ];
        }
    }
</script>
